import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import traslationEn from "./assets/locales/en/translation.json";
import traslationEs from "./assets/locales/es/translation.json";
import translationPt from "./assets/locales/pt/translation.json";
import { getPayload } from "./auth";

// Inicialización de variables
let payload = null;
let userLanguage = "";
let lng = "";
let fallbackLng = "";

// Intentar obtener el payload
try {
  if (window.location.pathname !== "/credentials") {
    payload = getPayload();
  }
} catch (error: unknown) {
  console.error("Error al obtener el payload:", error);
}

// Validar si hay un idioma en la configuración del payload
const applicationLanguage = payload?.setting?.applicationLanguage ?? null;
userLanguage = applicationLanguage || navigator.language;

// Definir el idioma y el idioma de respaldo
lng = userLanguage;
fallbackLng = userLanguage;

// Recursos de idiomas
const resources = {
  en: { translation: traslationEn },
  es: { translation: traslationEs },
  pt: { translation: translationPt },
};

// Inicializar i18n
i18n.use(initReactI18next).init({
  resources,
  lng,
  fallbackLng,
  keySeparator: false,
  detection: {
    checkWhitelist: true,
  },
  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
